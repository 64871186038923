.esignature-menu--signed-item {
  margin-bottom: var(--margin-sm);
  max-width: 100%;
}

.esignature-menu--signed-item .data-pill {
  /* background-color: var(--control-bg-on-gray); */
  background-color: var(--bg-green);
  box-shadow: none;
}

.esignature-menu--agreement-url {
  margin-bottom: var(--margin-sm);
  max-width: 100%;
}
.esignature-menu--agreement-url.yellow-pill .data-pill {
  background-color: var(--yellow-300);
  box-shadow: none;
}
.esignature-menu--agreement-url.yellow-pill a {
  color: var(--yellow-50);
}
.esignature-menu--agreement-url.yellow-pill .icon {
  --icon-stroke: var(--yellow-50);
}
.esignature-menu--agreement-url.green-pill .data-pill {
  background-color: var(--green-400);
  box-shadow: none;
}
.esignature-menu--agreement-url.green-pill a {
  color: var(--green-50);
}
.esignature-menu--agreement-url.green-pill .icon {
  --icon-stroke: var(--green-50);
}
.esignature-menu--agreement-url.blue-pill .data-pill {
  background-color: var(--blue-300);
  box-shadow: none;
}
.esignature-menu--agreement-url.blue-pill a {
  color: var(--blue-50);
}
.esignature-menu--agreement-url.blue-pill .icon {
  --icon-stroke: var(--blue-50);
}
.esignature-menu--agreement-url.red-pill .data-pill {
  background-color: var(--red-400);
  box-shadow: none;
}
.esignature-menu--agreement-url.red-pill a {
  color: var(--red-50);
}
.esignature-menu--agreement-url.red-pill .icon {
  --icon-stroke: var(--red-50);
}